html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background: 0 0;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: 700;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

html {
  line-height: 39px;
  font-size: 125%;
  font-family: "Maven Pro", sans-serif;
  color: #222222;
}

body {
  font-size: 16px;
}

img {
  width: 100%;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  color: inherit;
}

h4 {
  text-transform: uppercase;
}

h2 {
  font-size: 15px;
}

article {
  padding: 1rem 0;
}

img {
  width: 100%;
}

.wrapper {
  max-width: 1080px;
  width: 95%;
  margin: 0 auto;
}

.container {
  padding: 4rem 0;
}

.header {
  color: #7cae49;
  font-size: 2rem;
  font-weight: 700;
  text-align: left;
  padding-bottom: 2rem;
}

.paragraph {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.paragraphTerm {
  padding: 1rem 0;
}

.dotList {
  font-size: 14px;
  list-style: disc;
  margin-left: 50px;
}

.dotList li {
  padding: 5px 0;
}

.flexContainer {
  display: flex;
  flex-direction: column;
}

textarea {
  width: 80%;
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

a.skip-main:focus,
a.skip-main:active {
  color: #fff;
  background-color: #000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid yellow;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

h1,
h2,
p {
  font-family: "Montserrat", sans-serif;
  color: #3f3f3f;
}

.serviceContent h1 {
  text-align: center;
}

.serviceContent h2 {
  font-size: 1rem;
}

.servicesTitle {
  color: #3f3f3f;
  padding: 60px 0;
  font-size: 2.2rem;
}

.serviceSubtitle {
  text-align: center;
}

.work ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.work ul li {
  padding: 25px 55px;
  width: 18%;
}

.companyAccent {
  font-weight: 700;
  color: #2d8637;
  font-family: "Montserrat", sans-serif;
}

.serviceInfoDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.serviceInfo {
  padding: 25px 50px;
}

.serviceInfo p:nth-child(1),
.serviceInfo p:nth-child(2) {
  padding-bottom: 25px;
}

.quoteTodayButton {
  display: flex;
  align-items: center;
  width: 40%;
}

.serviceInsurance {
  text-align: center;
}

.serviceInsurance ul {
  padding-bottom: 50px;
}

.serviceInsurance h2,
.contactSection h3 {
  font-size: 1.75rem;
  padding: 30px 0 20px;
  font-family: "Montserrat", sans-serif;
  color: #3f3f3f;
}

.contactSection h3 {
  font-weight: 400;
}

.contactSection img {
  padding-top: 35px;
}

.serviceInsurance li p {
  font-size: 1.25rem;
  padding-bottom: 5px;
}

.rewardsProgram {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  width: 100%;
  padding: 25px 0;
}

.rewardsInfo {
  width: auto;
  text-align: center;
  padding: 10px 25px;
}

.vipProgram {
  width: 50%;
  padding-right: 0;
  align-self: center;
}

.contactSection {
  text-align: center;
  padding: 50px 0px 75px;
}

.contactSection img {
  width: auto;
}

.windowContent {
  padding: 35px 0px;
  width: 100%;
}

.windowContent h3 {
  font-size: 1.5em;
  padding-bottom: 10px;
}

.windowContent p {
  padding-bottom: 30px;
}

.mosquitoSubtitle {
  font-family: "Montserrat", sans-serif;
  font-size: 2em;
  color: #2d8637;
  text-align: center;
  padding-bottom: 25px;
}

.mosquitoPartnership {
  display: flex;
  justify-content: center;
}

.mosquitoPartnership p {
  padding: 40px 25px;
  font-size: 1.25em;
}

.partnershipLogo {
  width: 15%;
}

.mosquitoSubheader {
  padding: 40px 0px 20px;
  font-size: 1.2em;
}

.mosquitoContent {
  padding: 20px 0px;
  width: 100%;
}

.mosquitoSpecial {
  color: #2d8637;
  font-size: 1.6em;
  padding-bottom: 10px;
}

.homeSection1 {
  background: no-repeat 0% 50% url("../../media/Row of Houses.jpg");
  background-size: cover;
}

.homeH2 {
  text-align: right;
  font-size: 1.5rem;
  color: #fff;
  padding: 3rem 0;
  transition: all ease 0.5s;
  font-family: "Montserrat", sans-serif;
}

.greenRightGuarantee {
  padding-bottom: 10px;
  max-width: 581px;
}

.vipRewardsImg {
  padding-bottom: 10rem;
  max-width: 454px;
}

.homeAwardsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.readersChoice {
  padding-right: 15px;
  width: 293px;
}

.awardsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.threeBestRated,
.qualityImg {
  width: 293px;
}

.bannerImg {
  width: 450px;
}

hr {
  width: 95%;
  height: 1px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.13);
  border: transparent;
}

.homeService h3 {
  color: #2d8637;
  font-size: 1.8rem;
  font-weight: 300;
}

.homeService a {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.homeService img {
  width: 60%;
}

.homeCustomerRating {
  padding-bottom: 4rem;
  display: flex;
  justify-content: center;
}

.homeCustomerRatingContainer {
  width: 80%;
}

.starRatingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.starRatingContainer h5 {
  font-weight: 400;
  font-size: 1rem;
}

.starContainer {
  width: 90%;
}

.speechBubbleImg {
  width: 90%;
  display: flex;
  align-items: center;
}

.homeCustomerRatingContainer p {
  text-align: center;
  font-size: 1rem;
}

.homeInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeInfo {
  width: 80%;
}

.homeInfo li {
  padding: 1.5rem 0;
}

.titleGreen {
  padding: 2rem;
  color: #2d8637;
  font-size: 2rem;
  font-weight: 700;
}

.titleGreen,
.payment p,
.paymentButton {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.payment p {
  padding: 1rem 0;
  font-size: 0.7rem;
  line-height: 1.6;
}

.paymentButtonContainer {
  text-align: center;
  padding: 2rem 0;
}

.paymentButton,
.submitButton {
  font-size: 0.6rem;
  font-weight: 700;
  color: #28657c;
  text-transform: uppercase;
  border: 3px solid #28657c;
  padding: 1rem;
}
.paymentButton:visited,
.submitButton:visited {
  color: #28657c;
}
.paymentButton:hover, .paymentButton:focus,
.submitButton:hover,
.submitButton:focus {
  color: #fff;
  background-color: #28657c;
}

.green {
  color: #2d8637;
}

.googleContainer {
  text-align: center;
  padding-top: 2rem;
}

.googleThank {
  width: 40%;
}

.footer {
  border-top: 4px solid #28657c;
  margin-bottom: 50px;
}

.footerContainer {
  padding-top: 50px;
}

.footerHeading {
  text-align: center;
  color: #28657c;
  font-size: 24px;
}

.footerHeading a {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 5px;
}
.footerHeading a:hover, .footerHeading a:active, .footerHeading a:focus {
  background-color: #28657c;
  color: white;
}

.footerNav {
  padding-top: 90px;
}

.footerNav h4 {
  margin: 1rem 0;
  font-size: 16.25px;
  width: -moz-fit-content;
  width: fit-content;
}
.footerNav h4:hover, .footerNav h4:active, .footerNav h4:focus {
  background-color: #28657c;
  color: white;
}

.footerNav li {
  width: -moz-fit-content;
  width: fit-content;
  color: #28657c;
  font-size: 13px;
  font-weight: bold;
}
.footerNav li:hover, .footerNav li:active, .footerNav li:focus {
  background-color: #28657c;
  color: white;
  padding: 0 5px;
}

.footerNav ul:first-of-type {
  padding: 0px 0 100px 0;
}

.footerAddress {
  padding: 50px 0;
}

.footerLogo {
  width: 60%;
  padding-bottom: 10px;
}

address,
address a {
  font-family: "Maven Pro", sans-serif;
  color: #2d8637;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
}

address p {
  padding: 5px 0;
  color: #2d8637;
}

.copyrightContainer {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: rgb(0, 68, 98);
  white-space: normal;
  width: 85%;
}

.copyrightContainer a {
  padding: 0 5px;
  color: #28657c;
}
.copyrightContainer a:hover, .copyrightContainer a:active, .copyrightContainer a:focus {
  background-color: #28657c;
  color: white;
}

.privacy h2 {
  padding-top: 2rem;
}

.contactInfo {
  padding-bottom: 2rem;
}

.terms h2 {
  text-transform: uppercase;
  padding: 2rem 0;
}

.headerSplash {
  padding-top: 1rem;
  width: 100%;
  position: relative;
  transition: all ease 1s;
}

.stickyHeader {
  background-color: #f9f5ee;
  position: fixed;
  z-index: 5;
  top: 0;
  transition: all ease 1s;
}

.mobileMenuButton {
  border: none;
  background-color: transparent;
  color: #28657c;
  font-size: 1.5rem;
  position: relative;
}

.mobileMenuButton p {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.menuContainer {
  display: flex;
}

.headerLogoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 10px;
}

.logo,
.logoSticky {
  width: 110px;
  transition: all ease 0.5s;
}

.mobileNav {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  position: relative;
}

.mobileSticky {
  padding-bottom: 0;
  transition: all ease 1s;
}

.mobileNavContainer {
  display: flex;
}

.mainMobileNav {
  width: 100%;
  transition: all 500ms;
}

.subMobileNav {
  transform: translateX(400px);
  transition: all 500ms;
}

.moveAway {
  transform: translateX(-500px);
  transition: all 500ms;
}

.moveBack {
  width: 100%;
  transform: translateX(-50px);
  transition: all 500ms;
}

.mobileNav li {
  text-align: center;
  text-transform: uppercase;
  color: #737373;
}
.mobileNav li:active, .mobileNav li:hover, .mobileNav li:focus {
  color: #28657c;
}

.mobileNav a.active,
.highlight {
  color: #28657c;
}

.highlight {
  color: #28657c !important;
}

.subMenu a.active {
  color: #fff;
}

.subMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #28657c;
  width: 25%;
}

.subMenu li {
  color: #fff;
  word-wrap: normal;
  text-align: left;
  margin: 5px;
  line-height: 1.6;
}

.subMenu li:hover,
.subMenu li:focus {
  background-color: rgba(255, 255, 255, 0.13);
  color: #fff;
}

.deskNav li {
  padding: 0 5px;
}

.aboutGreenRight {
  text-align: center;
  width: auto;
  padding: 35px 75px;
}

.aboutSubcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subcontentPhoto {
  width: 50%;
}

.subcontentWindow {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
}

.learnMore {
  text-align: center;
  text-transform: uppercase;
  align-self: end;
  font-weight: 700;
  width: 150px;
  margin-top: 15px;
  padding: 5px 15px;
  background-color: #fff;
  color: #28657c;
  border: 3px solid #28657c;
  transition: 300ms all;
}

.learnMore:hover,
.learnMore:focus {
  background: #28657c;
  color: #fff;
  transition: 300ms all;
}

.onlineQuote {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
}

.onlineQuote h3 {
  color: #2d8637;
  font-weight: 400;
  font-size: 2em;
  line-height: 60px;
}

.quoteButton {
  width: 30%;
  align-self: center;
  padding-top: 25px;
}

.aboutUsContent h2 {
  font-size: 1rem;
}

.alwaysGreenSubcontent {
  padding: 25px;
}

.alwaysGreenSubcontent h2 {
  font-size: 1rem;
}

.greenContentWindow {
  width: auto;
  padding: 25px 0px;
}

.greenContentWindow p:nth-of-type(even) {
  padding-top: 25px;
}

.freeQuote {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.freeQuote h3 {
  color: #2d8637;
  font-weight: 400;
  font-size: 2em;
  width: auto;
  text-align: center;
}

.alwaysGreenQuoteButton {
  width: 35%;
  padding: 25px;
}

.rewardsHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 75px;
}

.rewardsHeader img {
  width: 50%;
}

.rewardsTitle {
  width: auto;
  text-align: center;
  font-size: 1.7em;
  padding: 25px;
}

.rewardsQA {
  padding: 25px 0;
}

.rewardsQuestion p {
  font-size: 1.5em;
  color: #000;
  padding-bottom: 10px;
}

.rewardsAnswer p {
  font-size: 1em;
}

.rewardsExit {
  text-align: center;
  font-size: 1.1em;
  padding-top: 50px;
}

.rewardsExit a {
  color: #2d8637 !important;
  font-family: "montserrat";
}
.rewardsExit a:hover, .rewardsExit a:focus {
  color: white !important;
}

.rewardsLogo {
  display: flex;
  justify-content: center;
}

.rewardsLogo img {
  width: auto;
  padding: 50px;
}

.rewardsHeader img {
  width: 60%;
}

.jobOppP {
  text-align: center;
  padding-bottom: 4rem;
}

.jobOppContainer h2 {
  font-family: "Montserrat", sans-serif;
}

.jobOppFormHeader {
  color: #2d8637;
  text-align: center;
  font-size: 1.3rem;
  padding: 2rem 0;
}

.formSection select {
  font-family: "Montserrat", sans-serif;
  background-color: inherit;
  border: 1px solid #222222;
  padding: 20px;
}

.formSection option {
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
}

.guaranteeContent {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
}

.guaranteeTitle,
.guaranteeInfo {
  width: auto;
}

.guaranteeTitle h2 {
  color: #2d8637;
  font-size: 2em;
}

.guaranteeAccent {
  font-weight: 700;
}

.freeQuote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.freeQuote h3 {
  font-size: 2em;
  width: 80%;
  text-align: center;
  font-weight: 400;
  color: #2d8637;
}

.customerReviewTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
}

.reviewBackground {
  width: auto;
}

.reviewTitle {
  width: auto;
  align-self: center;
  text-align: center;
  padding-top: 50px;
}

.reviewScore {
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
}

.reviewScore img {
  width: 30%;
  align-self: center;
}

.overallReviews {
  font-size: 1.2em;
}

.reviewSites ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
}

.reviewSites ul li {
  width: 50%;
  display: flex;
  align-self: center;
  padding: 40px 35px;
}

.reviewRating img {
  width: 25%;
}

.reviewContent {
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.contentWrap {
  max-width: 1080px;
  width: 80%;
  margin: 0 auto;
}

.reviewContent:nth-child(odd) {
  background-color: #12632a;
}

.cMessage,
.cName {
  font-size: 1.5em;
}

.cName {
  font-weight: 700;
  display: flex;
  justify-content: flex-end;
}

.reviewMessage p {
  color: #fff;
}

.reviewSecondary p {
  color: #2d8637;
}

.logoContainer {
  width: 50%;
}

.reusableContact a,
.reusableContact p {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  padding: 0;
}

.contactContainer {
  display: flex;
  flex-direction: column;
}

.contactFormContainer {
  padding: 2rem 0;
}

.contactFormContainer h3 {
  color: #2d8637;
  font: 1.5rem "Montserrat", sans-serif;
  padding: 1.5rem 0;
}

.contactFormContainer p {
  font-style: italic;
}

.formLabel {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

form h4 {
  text-transform: none;
}

.formSection {
  padding: 0.7rem 0;
}

.contactFormContainer span {
  color: #a80000;
}

.formNameContainer {
  display: flex;
}

.formNameContainer div {
  display: flex;
  flex-direction: column-reverse;
}

.contactFormContainer input {
  width: 80%;
  padding: 15px;
}

.submitButton {
  background: transparent;
  width: 30%;
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem;
  padding: 0.3rem;
}

.thankYou {
  text-align: center;
  font-size: 2rem;
  color: #2d8637;
  line-height: 2rem;
}

.thankYou div {
  padding: 2rem;
}

.thankYou h1 {
  line-height: 4rem;
}

.error {
  border: 1px solid red;
  padding: 5px;
}

.freeQuoteContainer h1 {
  padding-bottom: 1rem;
}

.freeQuoteContainer h2 {
  text-align: center;
  font: 1.5rem "Montserrat", sans-serif;
  font-weight: 700;
  color: #2d8637;
  padding-bottom: 5rem;
}

.formCheckbox {
  display: flex;
  flex-direction: column;
}

.formCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.formCheckbox label {
  position: relative;
  padding-left: 2rem;
}

.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #28657c;
  border-radius: 25%;
}

.formCheckbox input:checked ~ .checkmark {
  background-color: #28657c;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.formCheckbox input:checked ~ .checkmark:after {
  display: block;
}

.formCheckbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

@media (min-width: 800px) {
  h2 {
    font-size: 16px;
  }
  .wrapper {
    width: 90%;
  }
  .paragraph {
    font-size: 16px;
  }
  .dotList {
    font-size: 16px;
  }
  textarea {
    width: 100%;
  }
  .menuContainer {
    flex-direction: column;
    justify-content: space-between;
    margin: 0 3rem;
  }
  .logo {
    width: 220px;
  }
  .navigation {
    width: 100%;
    align-self: center;
  }
  .mobileNav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .homeH2 {
    text-align: center;
    padding-top: 9rem;
  }
  .awardsDiv {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
  .homeService {
    display: flex;
  }
  .homeService h3 {
    align-self: center;
    width: 90%;
  }
  .homeService img {
    width: 70%;
  }
  .homeCustomerRatingContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-right: 2rem;
    padding-left: 2rem;
    width: 100%;
  }
  .starContainer {
    width: 50%;
  }
  .speechBubbleImg {
    width: 100%;
  }
  .homeInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem;
  }
  .homeInfo2 {
    width: 40%;
    padding-left: 20px;
  }
  .homeGuarantee {
    display: flex;
    justify-content: center;
  }
  .homeGuarantee img {
    max-width: 774px;
  }
  .contactContainer {
    flex-direction: row;
    justify-content: space-between;
  }
  .contactFormContainer {
    padding-top: 0;
    width: 50%;
  }
  .reusableContactContainer {
    margin-left: 4rem;
  }
  .payment p {
    font-size: 1rem;
  }
  .paymentButtonContainer {
    padding: 4rem;
  }
  .paymentButton {
    font-size: 0.8rem;
  }
  .work ul {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .work ul li {
    padding: 25px 55px;
    width: 18%;
  }
  .serviceInfoDiv {
    flex-direction: row;
  }
  .quoteTodayButton {
    width: 80%;
  }
  .mosquitoPartnership {
    justify-content: flex-end;
  }
  .rewardsProgram {
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba(0, 0, 0, 0.13);
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    width: 100%;
    padding: 25px 0;
  }
  .rewardsInfo {
    width: 50%;
    text-align: right;
    padding: 10px 25px;
  }
  .vipProgram {
    width: 50%;
    padding-right: 25px;
    align-self: center;
  }
  .aboutGreenRight {
    text-align: center;
    width: auto;
    padding: 35px 75px;
  }
  .aboutSubcontent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .subcontentPhoto {
    width: 100%;
    padding-right: 50px;
  }
  .subcontentWindow {
    display: flex;
    flex-direction: column;
    padding: 50px 0;
  }
  .quoteButton {
    width: 20%;
  }
  .alwaysGreenSubcontent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px;
  }
  .greenPhoto {
    max-width: 50%;
  }
  .greenContentWindow {
    width: 75%;
    padding: 25px 0px 25px 25px;
  }
  .greenContentWindow p:nth-of-type(even) {
    padding-top: 25px;
  }
  .freeQuote {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .freeQuote h3 {
    width: 50%;
    text-align: right;
  }
  .alwaysGreenQuoteButton {
    width: 20%;
    padding: 25px;
  }
  .jobOppContainer article {
    margin-left: 4rem;
  }
  .customerReviewTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .customerReviewTitle h1 {
    text-align: center;
  }
  .reviewSites ul {
    display: flex;
    flex-direction: row;
    padding: 50px;
  }
  .reviewRating img {
    width: 35%;
  }
  .guaranteeContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding: 30px;
  }
  .guaranteeTitle {
    width: 30%;
  }
  .guaranteeInfo {
    width: 70%;
  }
  .freeQuote {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .freeQuote h3 {
    width: 50%;
    text-align: right;
  }
  .alwaysGreenQuoteButton {
    width: 20%;
    padding: 25px;
  }
  .rewardsHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 75px 0 35px;
  }
  .rewardsHeader img {
    width: 30%;
  }
  .rewardsTitle {
    width: 80%;
    text-align: center;
    font-size: 1.7em;
    padding: 50px;
  }
  .rewardsQA {
    padding: 25px 0;
  }
  .rewardsQuestion p {
    font-size: 1.7em;
    color: #000;
    padding-bottom: 10px;
  }
  .rewardsAnswer p {
    font-size: 1.2em;
  }
  .rewardsExit {
    text-align: center;
    font-size: 1.1em;
    padding-top: 50px;
  }
  .rewardsExit a {
    color: #8dc641;
    font-family: "montserrat";
  }
  .rewardsExit a:hover,
  .rewardsExit a:focus {
    background-color: #28657c;
    color: #fff;
  }
  .rewardsLogo {
    display: flex;
    justify-content: center;
  }
  .rewardsLogo img {
    width: auto;
    padding: 50px;
  }
  .footerNav {
    display: flex;
    justify-content: center;
  }
  .footerNav ul {
    width: 25%;
  }
  .footerAddress {
    padding: 0px;
    width: 33.3333333333%;
  }
  .footerLogo {
    max-width: 223px;
  }
}
@media (min-width: 1150px) {
  .menuContainer {
    flex-direction: row;
  }
  .headerLogoContainer {
    justify-content: flex-start;
    width: -moz-fit-content;
    width: fit-content;
  }
  .navigation {
    width: 60%;
  }
}